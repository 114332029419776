




















import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  name: 'StepsInfo',
})
export default class StepsInfo extends Vue {
  @Prop({ required: true })
  buttons!: []

  @Prop({ required: true })
  activeButton!: number

  get activeButtonData(): { id: number; title: string } {
    const activeButtonData = this.buttons.find(
      (button: any) => button.id === this.activeButton
    ) || { id: 1, title: 'Шаг 1' }
    return activeButtonData
  }
}
